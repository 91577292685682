import { Card, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'

// const useMultiAudio = urls => {
//     const [sources] = useState(
//         urls.map(url => {
//             return {
//                 url,
//                 audio: new Audio(url),
//             }
//         }),
//     )

//     const [players, setPlayers] = useState(
//         urls.map(url => {
//             return {
//                 url,
//                 playing: false,
//             }
//         }),
//     )

//     const toggle = targetIndex => () => {
//         const newPlayers = [...players]
//         const currentIndex = players.findIndex(p => p.playing === true)
//         if (currentIndex !== -1 && currentIndex !== targetIndex) {
//             newPlayers[currentIndex].playing = false
//             newPlayers[targetIndex].playing = true
//         } else if (currentIndex !== -1) {
//             newPlayers[targetIndex].playing = false
//         } else {
//             newPlayers[targetIndex].playing = true
//         }
//         setPlayers(newPlayers)
//     }

//     useEffect(() => {
//         sources.forEach((source, i) => {
//             players[i].playing ? source.audio.play() : source.audio.pause()
//         })
//     }, [sources, players])

//     useEffect(() => {
//         sources.forEach((source, i) => {
//             source.audio.addEventListener('ended', () => {
//                 const newPlayers = [...players]
//                 newPlayers[i].playing = false
//                 setPlayers(newPlayers)
//             })
//         })
//         return () => {
//             sources.forEach((source, i) => {
//                 source.audio.removeEventListener('ended', () => {
//                     const newPlayers = [...players]
//                     newPlayers[i].playing = false
//                     setPlayers(newPlayers)
//                 })
//             })
//         }
//     }, [])

//     return [players, toggle]
// }

const MultiPlayer = ({ urls, content }) => {
    // const [players, toggle] = useMultiAudio(urls)
    const [players, setPlayers] = useState([])
    const [sources, setSourses] = useState([])
    useEffect(() => {
        if (urls) {
            setSourses(urls.map(url => {
                return {
                    url,
                    audio: new Audio(url),
                }
            }))
            setPlayers(urls.map(url => {
                return {
                    url,
                    playing: false,
                }
            }))
        }
    }, [urls])
    useEffect(() => {
        sources.forEach((source, i) => {
            players[i].playing ? source.audio.play() : source.audio.pause()
        })
    }, [sources, players])
    useEffect(() => {
        sources.forEach((source, i) => {
            source.audio.addEventListener('ended', () => {
                const newPlayers = [...players]
                newPlayers[i].playing = false
                setPlayers(newPlayers)
            })
        })
        return () => {
            sources.forEach((source, i) => {
                source.audio.removeEventListener('ended', () => {
                    const newPlayers = [...players]
                    newPlayers[i].playing = false
                    setPlayers(newPlayers)
                })
            })
        }
    }, [])
    const toggle = targetIndex => () => {
        const newPlayers = [...players]
        const currentIndex = players.findIndex(p => p.playing === true)
        if (currentIndex !== -1 && currentIndex !== targetIndex) {
            newPlayers[currentIndex].playing = false
            newPlayers[targetIndex].playing = true
        } else if (currentIndex !== -1) {
            newPlayers[targetIndex].playing = false
        } else {
            newPlayers[targetIndex].playing = true
        }
        setPlayers(newPlayers)
    }
    return (
        <Grid container justifyContent="space-evenly">
            {
                players.map((player, i) => (
                    <Player key={i} player={player} toggle={toggle(i)} content={content[i]} />
                ))
            }
        </Grid>
    )
}

const Player = ({ player, toggle, content }) => (
    <Grid item>
        {/* <p>Stream URL: {player.url}</p> */}
        <Card style={{
            borderRadius: "10px",
            cursor: "pointer",
        }}>
            <Grid container style={{
                // padding: "20px",

                fontWeight: 'bold',
                fontSize: "40px",
                width: "170px",
                height: "170px",
                alignItems: "center",
                justifyContent: "center",

            }}
                onClick={toggle}
            >

                {content}
            </Grid>
        </Card>
        {/* <button onClick={toggle}>{player.playing ? 'Pause' : 'Play'}</button> */}
    </Grid>
)
export default MultiPlayer