import { Button, Card, CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import serverCall from '../../Util/serverCall';
const useStyles = makeStyles({
    question: {
        fontWeight: "bold"
    },
    root: {
        padding: "20px",
        width: "100%",
        backgroundColor: "#F7F7FF"
        // width: "400px"
    }

});
export default function Quiz(props) {
    const [quizes, setQuizes] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentQuizIndex, setCurrentQuizIndex] = useState(0)
    const [currentQuiz, setCurrentQuiz] = useState({})

    const [totalQuiz, settotalQuiz] = useState(0)
    const [isAnswered, setisAnswered] = useState(false)
    const [selectedAnswer, setselectedAnswer] = useState("")

    // const [loading, setLoading] = useState(false)

    const classes = useStyles(props)
    useEffect(() => {
        setLoading(true)
        serverCall({
            method: 'GET',
            url: `/api/quiz?id=${props.selectedLessonId}`,
        })
            .then(res => {
                setLoading(false)
                if (res.data.data.quiz) {
                    setQuizes(res.data.data.quiz)
                    setCurrentQuiz(res.data.data.quiz[0])
                    // settotalQuiz(res.data.data.quiz.length)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    const handleNext = () => {
        if (currentQuizIndex !== quizes.length - 1) {
            setisAnswered(false)
            setCurrentQuizIndex(currentQuizIndex + 1)
            setCurrentQuiz(quizes[currentQuizIndex + 1])
        }
    }
    const handleSelect = (value) => {
        setisAnswered(true)
        setselectedAnswer(value)
    }
    return (
        <Card className={classes.root}>
            {
                loading ? <CircularProgress />
                    :
                    Object.keys(currentQuiz).length > 0 &&
                    <Grid container spacing={2}>
                        <Grid item container className={classes.question}>
                            {currentQuiz.question}
                        </Grid>
                        {
                            currentQuiz.options.map(answer =>
                                <Grid container item
                                    onClick={() => handleSelect(answer)}

                                >
                                    <Grid container style={{
                                        backgroundColor: selectedAnswer && (selectedAnswer.option === answer.option) ? answer.is_answer ? "green" : "red" : "#fff",
                                        padding: "5px",
                                        justifyContent: "center",
                                        cursor: "pointer"
                                    }}>
                                        {answer.option}
                                    </Grid>
                                </Grid>
                            )
                        }
                        <Grid item container justifyContent="center">
                            <Button
                                classes={{
                                    root: classes.button
                                }}
                                onClick={handleNext}
                                disabled={(currentQuizIndex === quizes.length - 1) || !selectedAnswer.is_answer}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
            }
        </Card>
    )
}
