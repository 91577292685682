
import firebase from 'firebase';
var config = {
    apiKey: "AIzaSyA1V2yxAIHPYbz5QNQw7kwvmXLNeEIGtP4",
    authDomain: "sakirmyna-app.firebaseapp.com",
    databaseURL: "https://sakirmyna-app.firebaseio.com",
    projectId: "sakirmyna-app",
    storageBucket: "sakirmyna-app.appspot.com",
    messagingSenderId: "971556717289",
    appId: "1:971556717289:web:082acfb93f0264d52ea678",
    measurementId: "G-3KZEK66NXN"
}

  
firebase.initializeApp(config);
var auth = firebase.auth();
var provider = new firebase.auth.GoogleAuthProvider(); 

export {auth , firebase,provider};