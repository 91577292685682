import { Button, Card, CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import MultiPlayer from './MultiPlayerComponent';
const useStyles = makeStyles({
    root: {
        paddingTop: "20px",
        backgroundColor: "#F7F7FF",
        width:"100%"
    }
    // card: {
    //     padding: "20px",
    //     borderRadius: "10px",
    //     fontWeight: 'bold',
    //     fontSize: "40px",
    //     width: "150px",
    //     height: "150px",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     cursor: "pointer"
    // },
    // heading: {
    //     fontWeight: 'bold'
    // }

});
export default function ActivitiesComponent(props) {
    const classes = useStyles(props)
    const [index, setIndex] = useState(0)
    const handleNext = () => {
        if (index !== props.activities.length - 1) {
            setIndex(index + 1)
        }
    }
    const handlePrevious = () => {
        if (index !== 0) {
            setIndex(index - 1)
        }
    }
    return (
        <Card className={classes.root}>
            {
                props.loading ? <CircularProgress />
                    :
                    props.activities && props.activities.length > 0 ?
                        <Grid container spacing={2} >
                            <Grid item container>
                                <Grid container>
                                    <MultiPlayer
                                        urls={props.activities[index].contents.map(content => content.audio_path)}
                                        content={props.activities[index].contents.map(content => content.content)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="space-evenly">
                                <Button onClick={handlePrevious} disabled={index === 0}>
                                    Previous
                                </Button>
                                <Button onClick={handleNext} disabled={index === props.activities.length - 1}>
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                        : <>No activities found</>
            }


        </Card>
    )
}
