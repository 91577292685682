import axios from 'axios'
const URL = 'https://api.app.sakirmyna.com'
// const URL = "http://localhost:5900"

const serverCall = (config) => {
  if (localStorage.getItem('auth')) {
    const token = localStorage.getItem('auth')
    config.headers = {
      "Authorization": `Bearer ${token}`
    }
  }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
      }
      if(error.response.status===401){
        // Auth.logout()
        // registerNav.jumpTo('/login')
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
}

export const formDataServerCall = (config) => {
  //header authorization
  if (localStorage.getItem('auth')) {
    const token = localStorage.getItem('auth')
    config.headers = {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    }
  }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      // console.log("response",response);
      return response;
    },
    function (error) {
      console.log("error",error);
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
      }
      if(error.response.status===401){
        // Auth.logout()
        // registerNav.jumpTo('/login')
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
}


export const loginServerCall = (config) => {
  if (localStorage.getItem('auth')) {
    config.headers = {
      "Authorization": ``
    }
  }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
      }
      if(error.response.status===401){
        // Auth.logout()
        // registerNav.jumpTo('/login')
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
}
export default serverCall
