import { Button, Card, CircularProgress, Grid, IconButton, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
// import { useHistory } from 'react-router'
import serverCall from '../Util/serverCall'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useSpeechSynthesis } from 'react-speech-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
// import textToSpeech from '@google-cloud/text-to-speech'
import * as googleTTS from 'google-tts-api';
import Player from './Player';
// const textToSpeech = require('@google-cloud/text-to-speech');

// import { twiml } from 'twilio'


// const VoiceResponse = require('twilio').twiml.VoiceResponse;

// const response = new VoiceResponse();
// const say = response.say({
//     voice: 'Polly.Joanna'
// }, 'Hi');


const useStyles = makeStyles({
    root: {
        paddingTop: "25px"
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "50px",
        overflow: "visible",
        position: "relative",
        // backgroundColor: "#eee",
        width: "100%",
        zIndex:1,
        
    },
    heading: {
        fontWeight: 'bold'
    },
    fromCard: {
        padding: "10px",
        borderRadius: "50px",
        position: "absolute",
        top: "-25px",
        zIndex: 999,
        left: 'calc(50% - 70px)',
        cursor: "pointer",
        paddingLeft:"20px",
        paddingRight:"20px",
        userSelect:'none'
    },
    icon: {
        color: '#1976D2',
        marginLeft: "10px",
        fontSize: "20px"
    },
    iconButtonIcon: {
        color: '#1976D2',
        fontSize: "16px"
    },
    // disabled:{
    //     color:"#000"
    // }


});
export default function Translate(props) {
    const classes = useStyles(props)
    const [translation, settranslation] = useState({})
    const [from, setFrom] = useState('English')
    const [to, setTo] = useState('Arabic')
    const [text, setText] = useState(null)
    const [audioUrl, setAudioUrl] = useState("")
    // const [audio,setAudio] = useState(new Audio(""));
    const [loading, setLoading] = useState(false)
    // const history = useHistory()
    const { speak } = useSpeechSynthesis();


    // const response = new twiml.VoiceResponse();
    // response.say({
    //     voice: 'alice',
    //     language: 'pt-BR',
    //     loop: 2
    // }, 'Bom dia.');

    // const client = new textToSpeech.TextToSpeechClient();
    // const quickStart = async() => {
    //     const text = 'hello, world!';

    //     // Construct the request
    //     const request = {
    //         input: { text: text },
    //         // Select the language and SSML voice gender (optional)
    //         voice: { languageCode: 'en-US', ssmlGender: 'NEUTRAL' },
    //         // select the type of audio encoding
    //         audioConfig: { audioEncoding: 'MP3' },
    //     };

    //     // Performs the text-to-speech request
    //     const [response] = await client.synthesizeSpeech(request);
    //     // Write the binary audio content to a local file
    //     // const writeFile = util.promisify(fs.writeFile);
    //     // await writeFile('output.mp3', response.audioContent, 'binary');
    // }
    // // async function quickStart() {
    // //   // The text to synthesize

    // // }

    const handleSpeak = async (lang, text) => {
        const url = await googleTTS.getAudioUrl(text, {
            lang: lang,
            slow: false,
            host: 'https://translate.google.com.cn',
        })
        setAudioUrl(url)
        const audio = new Audio(url)
        audio.play()

    }


    const translate = (text) => {
        setLoading(true)
        serverCall({
            method: 'POST',
            url: '/api/translate',
            data: {
                text: text,
                target: to === 'English' ? 'en' : 'ar'
            }
        })
            .then(res => {
                setLoading(false)
                if (res.data.data) {
                    settranslation(res.data.data)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }

    return (
        <Grid container>
            <Grid container spacing={2} className={classes.root}>
                <Grid item container>
                    <Card className={classes.card} style={{
                        padding: "20px",
                        borderRadius: "10px",
                        paddingTop: "50px",
                        overflow: "visible",
                        position: "relative",
                        // backgroundColor: "#eee",
                        width: "100%",
                        zIndex:1,
                    }}>
                        <Card className={classes.fromCard} style={{
                            padding: "10px",
                            borderRadius: "50px",
                            position: "absolute",
                            top: "-25px",
                            zIndex: 999,
                            left: 'calc(50% - 70px)',
                            cursor: "pointer",
                            paddingLeft:"20px",
                            paddingRight:"20px",
                            userSelect:'none'
                        }} onClick={() => {
                            setFrom(from === "English" ? "Arabic" : 'English')
                            setTo(to === "English" ? "Arabic" : 'English')
                        }}>
                            {from} <CompareArrowsIcon fontSize="small" /> {to}
                        </Card>
                        <Grid container justifyContent="space-between" spacing={2} alignItems="center">
                            <Grid container lg={6} item>
                                <Grid container item>
                                    <TextField
                                        placeholder="Type"
                                        // value={text}
                                        // onChange={(e) => setText(e.target.value)}
                                        onChange={
                                            (e) => {
                                                setText(e.target.value)
                                                translate(e.target.value)
                                            }
                                        }
                                        fullWidth
                                        minRows={4}
                                        multiline
                                    />
                                </Grid>
                                <Grid container item >
                                    <IconButton
                                        style={{
                                            padding: "10px"
                                        }}
                                        // onClick={() => response.say({
                                        //     voice: 'alice',
                                        //     language: 'pt-BR',
                                        //     loop: 2
                                        // }, 'Bom dia.')}
                                        // onClick={() => speak({
                                        //     text: text,
                                        //     SpeechSynthesisVoice: [
                                        //         {
                                        //             default: true,
                                        //             lang: "ar-KW",
                                        //             localService: true,
                                        //             name: "Karen",
                                        //             voiceURI: "Karen",
                                        //         }
                                        //     ]
                                        // })}
                                        onClick={() => handleSpeak(from === 'English' ? 'en' : 'ar', text)}
                                        // onClick={()=>quickStart()}
                                        disabled={text === null}
                                    >
                                        <FontAwesomeIcon icon={faVolumeUp} className={classes.iconButtonIcon} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid container lg={6} item>

                                <Grid container alignItems="center" style={{
                                    fontWeight: 'bold'
                                }}>

                                    <Grid container item>
                                        <TextField
                                            placeholder="Type"
                                            value={loading ? "Loading..." : translation && translation.translation ? translation.translation : "Your result will show here"}
                                            onChange={(e) => setText(e.target.value)}
                                            fullWidth
                                            minRows={4}
                                            multiline
                                            disabled
                                            InputProps={{
                                                classes: { disabled: classes.disabled }
                                            }}
                                        />
                                    </Grid>
                                    <IconButton
                                        style={{
                                            padding: "10px"
                                        }}
                                        // onClick={() => speak({
                                        //     text: translation.translation,
                                        // })}
                                        onClick={() => handleSpeak(
                                            to === 'English' ? 'en' : 'ar',
                                            loading ? "Loading" : translation && translation.translation ? translation.translation : "Your result will show here"
                                        )}
                                        disabled={text === null}
                                    >
                                        <FontAwesomeIcon icon={faVolumeUp} className={classes.iconButtonIcon} />
                                    </IconButton>

                                </Grid>
                            </Grid>
                            {/* <Grid item container>
                            <Button onClick={translate} disabled={text === null || loading}>
                                Translate <FontAwesomeIcon icon={faLanguage} className={classes.icon} />
                                {loading && <CircularProgress />}
                            </Button>
                        </Grid> */}
                        </Grid>
                    </Card>
                </Grid>
                {/* {
                audioUrl && <audio src={audioUrl} controls />
            } */}
            </Grid>
        </Grid>
    )
}
