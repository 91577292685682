import { Button, Card, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import { firebase, auth } from '../firebase';

const useStyles = makeStyles({
    // root: {
    //     height: "100vh",
    //     alignItems: "center",
    //     backgroundColor: '#eeee',
    // },
    card: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%"
    },
    // heading: {
    //     fontSize: "20px",
    //     fontWeight: "700"
    // },
    loginButton: {
        width: "70%"
    },
    loginButton2:{
        width:"45%"
    },

    forgot: {
        cursor: "pointer",
        color: "blue"
    }
});
export default function RegisterWithEmailAndPassword(props) {
    const classes = useStyles(props)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [tab, setTab] = useState(0)


    const [confirmPassword, setConfirmPassword] = useState("")
    // const createUserWithEmailAndPassword = () => {
    //     auth.createUserWithEmailAndPassword(email, password).then(res => {
    //     }).catch(alert);
    // }
    // const signInWithEmailAndPassword = () => {
    //     auth.signInWithEmailAndPassword(email, password).then(res => {
    //     }).catch(alert);
    // }

    return (
        <Card className={classes.card}>
            {tab === 1 ?
                <Grid container spacing={2}>
                    <Grid item container>
                        <TextField placeholder="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
                    </Grid>
                    <Grid item container>
                        <TextField placeholder="Password" fullWidth onChange={(e) => setPassword(e.target.value)} />
                    </Grid>
                    <Grid item container>
                        <TextField placeholder="Confirm Password" fullWidth onChange={(e) => setConfirmPassword(e.target.value)} />
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Button
                            variant="contained"
                            className={classes.loginButton}
                            // onClick={createUserWithEmailAndPassword}
                            onClick={() => props.createUserWithEmailAndPassword(email, password)}

                        >
                            Sign up
                        </Button>
                    </Grid>
                    <Grid item container className={classes.forgot} onClick={() => setTab(2)}>
                        Forgot password?
                    </Grid>
                    <Grid item container justifyContent="center" >
                        <Button
                            variant="contained"
                            className={classes.loginButton}
                            onClick={() => setTab(0)}
                        >
                            Log in
                        </Button>
                    </Grid>
                </Grid>
                :
                tab == 0 ?
                    <Grid container spacing={2}>
                        <Grid item container>
                            <TextField placeholder="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
                        </Grid>
                        <Grid item container>
                            <TextField placeholder="Password" fullWidth onChange={(e) => setPassword(e.target.value)} />
                        </Grid>
                        <Grid item container justifyContent="center">
                            <Button
                                variant="contained"
                                className={classes.loginButton}
                                // onClick={signInWithEmailAndPassword}
                                onClick={() => props.signInWithEmailAndPassword(email, password)}

                            >
                                Log in with E-mail
                            </Button>
                        </Grid>
                        <Grid item container className={classes.forgot} onClick={() => setTab(2)}>
                            Forgot password?
                        </Grid>
                        <Grid item container justifyContent="center" >
                            <Button
                                variant="contained"
                                className={classes.loginButton}
                                onClick={() => setTab(1)}
                            >
                                Register
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                        <Grid item container>
                            Enter the registered email address we will send the link to reset your password if the mail registered with us
                        </Grid>
                        <Grid item container>
                            <TextField placeholder="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
                        </Grid>
                        {/* <Grid item container>
                            <TextField placeholder="Password" fullWidth onChange={(e) => setPassword(e.target.value)} />
                        </Grid> */}
                        <Grid item container justifyContent="center">
                            <Button
                                variant="contained"
                                className={classes.loginButton}
                                // onClick={signInWithEmailAndPassword}
                                onClick={() => props.forgotPasswordMail(email)}
                                disabled={!email}
                            >
                                Send Mail
                            </Button>
                        </Grid>
                        <Grid item container justifyContent="space-around" >
                            <Button
                                variant="outlined"
                                className={classes.loginButton2}
                                onClick={() => setTab(1)}
                            >
                                Register
                            </Button>
                            <Button
                                variant="outlined"
                                className={classes.loginButton2}
                                onClick={() => setTab(0)}
                            >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
            }

        </Card >
    )
}
