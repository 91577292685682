
import { Button, CircularProgress, Grid, Avatar,Card } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import serverCall from '../Util/serverCall'
const useStyles = makeStyles({
    root: {
        padding: "50px"
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "50px",
        position: "relative",
        backgroundColor: "#eee",
    },
    heading: {
        fontWeight: 'bold'
    },
    fromCard: {
        padding: "10px",
        borderRadius: "25px",
        position: "absolute",
        top: "-25px",
        zIndex: 999,
        left: "30%",
        cursor: "pointer"
    },
    mainCard:{
        padding:"20px",
        width:"100%",
        // backgroundColor:"#eee",
        // borderBottom:"4px solid blueviolet",
        borderRadius:"10px"
    }


});
export default function Profile(props) {
    const classes = useStyles(props)
    const [user, setUser] = useState({})

    const [loading, setLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        setLoading(true)
        serverCall({
            method: 'GET',
            url: '/api/profile',
        })
            .then(res => {
                setLoading(false)
                if (res.data.data) {
                    setUser(res.data.data)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])


    return (
        <Grid container className={classes.root} justifyContent="center"  >
        <Grid item lg={4} md={6}>
        <Card className={classes.mainCard}>
            {
                loading ?
                    <CircularProgress />
                    : Object.keys(user).length > 0 ?
                        <Grid container spacing={2}>
                            <Grid item container>
                                <Avatar />
                            </Grid>
                            <Grid item container style={{
                                fontWeight:"bold"
                            }}>
                              Welcome  {user.email}
                            </Grid>
                            <Grid item container>
                                <Button onClick={() => history.push('/editProfile')}>
                                    Edit profile
                                </Button>
                            </Grid>
                            <Grid item container>
                                <Button onClick={() => history.push('/support')}>
                                    Help
                                </Button>
                            </Grid>
                        </Grid>
                        : <>something went wrong</>
            }
    </Card >
    </Grid>
        </Grid>
    )
}
