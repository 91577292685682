import { Button, Card, CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import serverCall from '../../Util/serverCall';
import ActivitiesComponent from './ActivitiesComponent';
import CalssComponent from './CalssComponent';
import Quiz from './Quiz';
import Reviews from './Reviews';
const useStyles = makeStyles({
    root: {
        // padding: "20px"
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%"
    },
    rootCard: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%",
    },
    heading: {
        fontWeight: 'bold'
    },
    fromCard: {
        padding: "10px",
        borderRadius: "25px",
        position: "absolute",
        top: "-25px",
        zIndex: 999,
        left: "30%",
        cursor: "pointer",
    },
    video: {
        width: "100%",
        height: "200px"
    }

});
export default function LessonDetails(props) {
    const classes = useStyles(props)
    const [lessons, setLessons] = useState([])
    const [loading, setLoading] = useState(false)
    const [activities, setActivities] = useState([])

    const [loadingTab, setLoadingTab] = useState(false)

    const [selectedLesson, setSelectedLesson] = useState({})
    const [tab, setTab] = useState(0)

    useEffect(() => {
        if (tab === 1 && Object.keys(selectedLesson).length > 0) {
            setLoadingTab(true)
            serverCall({
                method: 'GET',
                url: `/api/activities?id=${selectedLesson._id}`,
            })
                .then(res => {
                    setLoadingTab(false)
                    if (res.data.data.activities) {
                        setActivities(res.data.data.activities)
                    }
                })
                .catch(err => {
                    setLoadingTab(false)
                })
        }
    }, [tab])
    useEffect(() => {
        setLoading(true)
        serverCall({
            method: 'GET',
            url: '/api/lessons',
        })
            .then(res => {
                setLoading(false)
                if (res.data.data.lessons) {
                    setLessons(res.data.data.lessons)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        if (lessons.length > 0) {
            const lesson = lessons.filter(lesson => lesson.lesson_index == props.selectedLesson)
            if (lesson.length > 0) {
                setSelectedLesson(lesson[0])
            }
        }
    }, [lessons, props.selectedLesson])
    useEffect(()=>{
        setTab(0)
    },[props.selectedLesson])
    return (
        <Card className={classes.rootCard}>
            <Grid
                container
                className={classes.root}
                justifyContent="space-between"
                spacing={2}
                alignItems="flex-start"
            >
                <Grid item>
                    <Button onClick={() => setTab(0)}
                        style={{
                            color: tab === 0 ? '#8F8F8F' : '#1976D2',
                            borderBottom: tab === 0 ? "2px solid #8F8F8F" : 'inherit'
                        }}
                    >
                        Class
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => setTab(1)}
                        style={{
                            color: tab === 1 ? '#8F8F8F' : '#1976D2',
                            borderBottom: tab === 1 ? "2px solid #8F8F8F" : 'inherit'
                        }}
                    >
                        Activities
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => setTab(2)}
                        style={{
                            color: tab === 2 ? '#8F8F8F' : '#1976D2',
                            borderBottom: tab === 2 ? "2px solid #8F8F8F" : 'inherit'
                        }}
                    >
                        Quiz
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => setTab(3)}
                        style={{
                            color: tab === 3 ? '#8F8F8F' : '#1976D2',
                            borderBottom: tab === 3 ? "2px solid #8F8F8F" : 'inherit'
                        }}
                    >
                        review
                    </Button>
                </Grid>
                <Grid container item>
                    {
                        loading ? <CircularProgress />
                            :
                            tab === 0 ?
                                <CalssComponent selectedLesson={selectedLesson} />
                                :
                                tab === 1 ?
                                    <ActivitiesComponent loading={loadingTab} activities={activities} />
                                    : tab === 2 ?
                                        <Quiz selectedLessonId={selectedLesson._id} />
                                        : tab === 3 ?
                                            <Reviews selectedLessonId={selectedLesson._id} />
                                            : null
                    }
                </Grid>
            </Grid>
        </Card>
    )
}
