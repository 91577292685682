import { Grid } from '@mui/material'
import React from 'react'
import UserAppBar from './UserAppBar'

export default function DefaultLayout({children}) {
    return (
        <div>
            <UserAppBar />
            <Grid container style={{
                backgroundColor:"#F7F7FF",
                minHeight:"90vh"
            }}>
            {children}
            </Grid>
        </div>
    )
}
