import { Button, Card, CircularProgress, Grid, TextField, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import serverCall, { formDataServerCall } from '../../Util/serverCall';
// import useRecorder from './ReviewComponents/useRecorder';
import MicIcon from '@mui/icons-material/Mic';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import MicRecorder from 'mic-recorder-to-mp3'
import lamejs from 'lamejs';
;

const useStyles = makeStyles({

    card: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%",
        backgroundColor: "#F7F7FF"
    },
});
export default function Reviews(props) {
    const classes = useStyles(props)
    const [loading, setLoading] = useState(false)
    const [Messages, setMessages] = useState([])
    const [MessageIndex, setMessageIndex] = useState(0)
    const [Msg, setMsg] = useState("")
    const [isReviewComplete, setisReviewComplete] = useState(false)
    const [answerText, setAnswerText] = useState("")
    // let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
    const [audioURL, setAudioURL] = useState("");
    const [audioData, setAudioData] = useState("");
    const [isBlocked, setisBlocked] = useState(false)
    const [mp3, setmp3] = useState('')

    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [blobURL, setblobURL] = useState(null);

    // useEffect(() => {
    //     navigator.mediaDevices.getUserMedia({ audio: true },
    //         () => {
    //             // console.log('Permission Granted');
    //             setisBlocked(false)
    //             // this.setState({ isBlocked: false });
    //         },
    //         () => {
    //             // console.log('Permission Denied');
    //             setisBlocked(true)
    //             // this.setState({ isBlocked: true })
    //         },
    //     );
    // }, [])
    async function requestRecorder() {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        return new MediaRecorder(stream);
    }
    const startRecording = () => {
        // console.log("start recording");
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };
    useEffect(async () => {
        // Lazily obtain recorder first time we're recording.
        if (recorder === null) {
            if (isRecording) {
                requestRecorder().then(setRecorder, console.error);
            }
            return;
        }

        if (isBlocked) {
            // console.log("access denied");
        }
        else {
            // Manage recorder state.
            if (isRecording) {
                recorder.start();
            } else {
                recorder.stop();
            }

            // Obtain the audio when ready.
            const handleData = async (e) => {
                // const fileMp3 = await new Blob(e.data, { type: 'audio/mp3' })
                // console.log("tttt", fileMp3);
                // const lib = new lamejs();
                // const mp3encoder = new lamejs.Mp3Encoder(1, 44100, 128); //mono 44.1khz encode to 128kbps
                // const samples = new Int16Array(44100); //one second of silence
                // var mp3data = mp3encoder.encodeBuffer(samples); //encode mp3
                // console.log("mp3data",mp3data);
                setmp3(e.data)
                setAudioURL(URL.createObjectURL(e.data));
                setAudioData(e.data)
                // console.log("URL.createObjectURL(e.data)", e.data);
            };

            recorder.addEventListener("dataavailable", handleData);
            return () => recorder.removeEventListener("dataavailable", handleData);
        }
    }, [recorder, isRecording]);


    // useEffect(() => {
    //     const recorder = new MicRecorder({
    //             bitRate: 128
    //         });
    //     if (isRecording) {
    //         console.log("yes");


    //         // Manage recorder state.
    //         if (isRecording) {
    //             recorder.start().then(() => {
    //                 // something else
    //             }).catch((e) => {
    //                 console.error(e);
    //             });
    //         } else {
    //             recorder.stop()
    //                 .getMp3().then(([buffer, blob]) => {
    //                     // do what ever you want with buffer and blob
    //                     // Example: Create a mp3 file and play
    //                     const file = new File(buffer, 'me-at-thevoice.mp3', {
    //                         type: blob.type,
    //                         lastModified: Date.now()
    //                     });
    //                     console.log("ffffffff", file);
    //                     setAudioURL(new Audio(URL.createObjectURL(file)))
    //                     setAudioData(file)
    //                     // const player = new Audio(URL.createObjectURL(file));
    //                     // player.play();



    //                 }).catch((e) => {
    //                     alert('We could not retrieve your message');
    //                     console.log(e);
    //                 });
    //         }
    //     }
    // }, [isRecording]);


    // const startRecording = () => {
    //     // console.log("start recording");
    //     // setIsRecording(true);
    //     if (isBlocked) {
    //         console.log('Permission Denied');
    //     } else {
    //         Mp3Recorder
    //             .start()
    //             .then(() => {
    //                 setIsRecording(true)
    //                 // this.setState({ isRecording: true });
    //             }).catch((e) => console.error(e));
    //     }
    // };

    // const stopRecording = () => {
    //     // setIsRecording(false);
    //     Mp3Recorder
    //         .stop()
    //         .getMp3()
    //         .then(([buffer, blob]) => {
    //             const blobURL = URL.createObjectURL(blob)
    //             setblobURL(blobURL)
    //             setIsRecording(false)
    //             // this.setState({ blobURL, isRecording: false });
    //         }).catch((e) => console.log(e));
    // };
    useEffect(() => {
        // console.log("props.selectedLessonId", props.selectedLessonId)
        setLoading(true)
        serverCall({
            method: 'GET',
            url: `/api/chat?lesson_id=${props.selectedLessonId}`,
        })
            .then(res => {
                setLoading(false)
                if (res.data.data.messages) {
                    // console.log("mmmessage", res.data.data);
                    setMessages(res.data.data.messages)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    const handleSubmit = () => {
        setMsg("Review submitted successfully")
    }

    const handleSendAudio = () => {
        // console.log("new upload",URL.createObjectURL(mp3));
        if (MessageIndex + 1 < Messages.length) {
            let formData = new FormData();
            formData.append('attachment', mp3);
            formData.append('lesson_id', props.selectedLessonId);
            formData.append('message_id', Messages[MessageIndex]._id);
            formData.append('message_type', Messages[MessageIndex].accept_answer);
            formData.append('message', answerText);
            formDataServerCall({
                method: 'POST',
                url: '/api/reviewweb',
                data: formData
            })
                .then(res => {
                    // setLoading(false)
                    // console.log("reeeeeee", res)
                    // if (res.data.data.messages) {
                    //     setMessages(res.data.data.messages)
                    // }
                })
                .catch(err => {
                    // setLoading(false)
                    // console.log("errrrrrrrrr", err)

                })

            setMessageIndex(MessageIndex + 1)
            setAudioURL("")
            setAudioData("")
            setAnswerText("")
        }
        else {
            let formData = new FormData();
            formData.append('attachment', mp3);
            formData.append('lesson_id', props.selectedLessonId);
            formData.append('message_id', Messages[MessageIndex]._id);
            formData.append('message_type', Messages[MessageIndex].accept_answer);
            formData.append('message', answerText);
            formDataServerCall({
                method: 'POST',
                url: '/api/reviewweb',
                data: formData
            })
                .then(res => {
                    // setLoading(false)
                    // console.log("reeeeeee", res)
                    // if (res.data.data.messages) {
                    //     setMessages(res.data.data.messages)
                    // }
                })
                .catch(err => {
                    // setLoading(false)
                    // console.log("errrrrrrrrr", err)

                })
            setisReviewComplete(true)
        }
    }
    const handleSend = () => {
        if (MessageIndex + 1 < Messages.length) {
            // let formData = new FormData();
            // formData.append('attachment', 'ABC');  
            serverCall({
                method: 'POST',
                url: '/api/reviewweb',
                data: {
                    lesson_id: props.selectedLessonId,
                    message_id: Messages[MessageIndex]._id,
                    message_type: Messages[MessageIndex].accept_answer,
                    message: answerText
                }
            })
                .then(res => {
                    // setLoading(false)
                    // console.log("reeeeeee", res)
                    // if (res.data.data.messages) {
                    //     setMessages(res.data.data.messages)
                    // }
                })
                .catch(err => {
                    // setLoading(false)
                    // console.log("errrrrrrrrr", err)

                })



            setMessageIndex(MessageIndex + 1)
            setAnswerText("")
        }
        else {
            setisReviewComplete(true)
        }


    }
    return (
        <Card className={classes.card}>
            {
                loading ? <CircularProgress /> :
                    <Grid container >
                        {
                            Messages.length > 0 ?
                                !isReviewComplete ?
                                    <Grid container spacing={2}>
                                        <Grid container item justifyContent="flex-start" lg={7} md={7} sm={7} xs={9} >
                                            {
                                                Messages[MessageIndex].message_type === "text_audio" ?
                                                    <Grid container spacing={1}>
                                                        <Grid item container >
                                                            {Messages[MessageIndex].message}                                                </Grid>
                                                        <Grid item container >
                                                            <audio src={Messages[MessageIndex].attachment} controls />
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    Messages[MessageIndex].message
                                            }
                                        </Grid>
                                        <Grid container item lg={5} md={5} sm={5} xs={3} >

                                        </Grid>
                                        <Grid container item lg={5} md={5} sm={5} xs={3} >

                                        </Grid>
                                        {
                                            Messages[MessageIndex].accept_answer === "text" ?
                                                <Grid container item justifyContent="flex-end" lg={7} md={7} sm={7} xs={9} >
                                                    <TextField
                                                        placeholder="Answer text"
                                                        onChange={(e) => setAnswerText(e.target.value)}
                                                        value={answerText}
                                                    />
                                                    <Grid container justifyContent="center">
                                                        <Button onClick={handleSend}>
                                                            Send
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                : <Grid container item justifyContent="center" lg={7} md={7} sm={7} xs={9}>
                                                    <Grid container spacing={2} >
                                                        {
                                                            audioURL ?
                                                                <Grid item container justifyContent="end">
                                                                    <audio src={audioURL} controls />
                                                                </Grid>
                                                                :
                                                                <Grid item container justifyContent="space-evenly">
                                                                    {
                                                                        !isRecording ?
                                                                            <IconButton onClick={startRecording} disabled={isRecording}>
                                                                                <MicIcon />
                                                                            </IconButton>
                                                                            :
                                                                            <IconButton onClick={stopRecording} disabled={!isRecording}>
                                                                                <StopCircleIcon />
                                                                            </IconButton>
                                                                    }
                                                                </Grid>
                                                        }
                                                        <Grid item container justifyContent="center">
                                                            {
                                                                !isRecording && audioURL &&
                                                                <Button onClick={handleSendAudio} >
                                                                    Send audio
                                                                </Button>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        }
                                        {/* {
                                        // MessageIndex + 1 === Messages.length &&
                                        isReviewComplete &&
                                        <Grid item container justifyContent="center">
                                             <Button onClick={handleSubmit} >
                                                Submit
                                            </Button> 
                                            Review completed successfully
                                        </Grid>
                                    } */}

                                        {/* {
                                        Msg
                                    } */}
                                    </Grid>
                                    :
                                    <Grid item container justifyContent="center">
                                        {/* <Button onClick={handleSubmit} >
                                                Submit
                                            </Button> */}
                                        Review completed successfully
                                    </Grid>
                                : <>No messsge</>
                        }
                    </Grid>
            }
        </Card>
    )
}
