import { Button, Card, Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
// import { Card, Grid, Typography } from '@material-ui/core'
// import { makeStyles } from '@material-ui/styles'
import CountrySelect from './CountryCodePicker'
import { firebase, auth, provider } from '../firebase';
import OtpPage from './OtpPage';
import RegisterWithEmailAndPassword from './RegisterWithEmailAndPassword';
import { loginServerCall } from '../Util/serverCall';
import { useHistory } from 'react-router';

// const useStyles = makeStyles({
//     root: {
//         height: "100vh",
//         alignItems: "center"
//     },
//     card: {
//         padding: "20px",
//         borderRadius: "10px",
//         width: "100%"
//     },
//     heading: {
//         fontSize: "20px",
//         fontWeight: "600"
//     }
// })
const useStyles = makeStyles({
    root: {
        height: "100vh",
        alignItems: "center",
        backgroundColor: '#eeee',
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        width: "100%"
    },
    heading: {
        fontSize: "20px",
        fontWeight: "700"
    },
    loginPhoneButton: {
        width: "70%"
    }
});

export default function Auth(props) {
    const classes = useStyles(props)
    const [countryCode, setCountryCode] = useState("+91")
    const [phoneNumber, setPhoneNumber] = useState("")
    // const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [validatingOtp, setvalidatingOtp] = useState(false);
    const [withEmailPass, setWithEmailPass] = useState(false);
    const history = useHistory()
    const createUserWithEmailAndPassword = (email,password) => {
        auth.createUserWithEmailAndPassword(email, password).then(res => {
            postRequestWithoutAutheAccept(res.user.email,"email")
        }).catch(alert);
    }
    const signInWithEmailAndPassword = (email,password) => {
        auth.signInWithEmailAndPassword(email, password).then(res => {
            postRequestWithoutAutheAccept("email",res.user.email)
        }).catch(alert);
    }
    const forgotPasswordMail = (email) =>{
        auth.sendPasswordResetEmail(email).then(res=>{
            // console.log("ressss",res);
        }).catch(err=>{
            // console.log(err);
        })
    }
    const postRequestWithoutAutheAccept =(type,value)=>{
        var data = {}
        if(type==="email"){
            data = {
                "type":"email",
                "email":value,
                "user_name":value
            }
        }
        if(type==="phone_number"){
            data = {
                "type":"phone_number",
                "phone_number":value,
                "user_name":value
            }
        }
        
        loginServerCall({
            method: 'POST',
            url: '/api/fetch/token',
            data: data,
            Headers:{
                "Content-Type": "application/x-www-form-urlencoded",
            }
        }).then(res=>{
            if(res.data.data){
                if(res.data.data.access_token){
                    localStorage.setItem('auth',res.data.data.access_token)
                    history.push('/dashboard')
                }
            }
        }).catch(err=>{
            // console.log("/api/fetch/token err",err);
        })
        
    }

    const [final, setfinal] = useState('');
    const signInWithGoogle = () => {
        auth.signInWithPopup(provider).then(res => {

            postRequestWithoutAutheAccept('email', res.user.email)
        }).catch(alert);
    }
    
    const signinwithphone = (phone) => {
        if (phone === "") return;
        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        auth.signInWithPhoneNumber(phone, verify).then((result) => {
            setfinal(result);
            // alert("code sent")
            setshow(true);
        })
            .catch((err) => {
                alert(err);
                window.location.reload()
            });
    }
    const ValidateOtp = (otp) => {
        setvalidatingOtp(true)
        if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
            setvalidatingOtp(false)
            // alert("Otp match");
            postRequestWithoutAutheAccept("phone_number",countryCode + "" + phoneNumber)
        }).catch((err) => {
            setvalidatingOtp(false)
            alert("Wrong code");
        })
    }
    return (
        <Grid container justifyContent="center" className={classes.root}>
            <Grid container lg={4} md={6} sm={8} xs={10}>
                {
                    withEmailPass ? <RegisterWithEmailAndPassword
                     createUserWithEmailAndPassword={createUserWithEmailAndPassword}
                     signInWithEmailAndPassword={signInWithEmailAndPassword}
                     forgotPasswordMail={forgotPasswordMail}
                      /> :
                        !show ? <Card className={classes.card}>
                            <Grid container spacing={2}>
                                <Grid item container className={classes.heading} >
                                    Log in to Myna Learning App
                                </Grid>
                                <Grid item container>
                                    <Typography>
                                        Phone number
                                    </Typography>
                                </Grid>

                                <Grid item container >
                                    <Grid item container lg={3} md={3} sm={3} xs={3}>
                                        <CountrySelect onChange={(value) => setCountryCode(value)} />
                                    </Grid>
                                    <Grid item container lg={9} md={9} sm={9} xs={9}>

                                        <TextField
                                            placeholder="Phone Number"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            style={{
                                                marginLeft: "10px"
                                            }}
                                            fullWidth
                                        />
                                    </Grid>

                                </Grid>
                                <Grid item container>
                                    <Typography>We will text you to confirm your number</Typography>
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <div id="recaptcha-container"></div>
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <Button
                                        onClick={() => signinwithphone(countryCode + "" + phoneNumber)}
                                        variant="contained"
                                        className={classes.loginPhoneButton}
                                    >
                                        Login with phone number
                                    </Button>
                                </Grid>
                                <Grid item container justifyContent="center">
                                    Or
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <Button
                                        // onClick={() => signinwithphone(countryCode + "" + phoneNumber)}
                                        onClick={() => setWithEmailPass(true)}
                                        variant="contained"
                                        className={classes.loginPhoneButton}
                                    >
                                        Continue with E-mail
                                    </Button>
                                </Grid>
                                <Grid item container justifyContent="center">
                                    <Button
                                        // onClick={() => signinwithphone(countryCode + "" + phoneNumber)}
                                        onClick={signInWithGoogle}
                                        variant="contained"
                                        className={classes.loginPhoneButton}
                                    >
                                        Login with Google
                                    </Button>
                                </Grid>

                            </Grid>


                        </Card>
                            : <OtpPage
                                phone={countryCode + "" + phoneNumber}
                                validatingOtp={validatingOtp}
                                ValidateOtp={ValidateOtp}
                            />
                }
            </Grid >
        </Grid >
    )
}
